export const aqar_cr2=[
  {
    "title": "2.2.1",
    "filename": "./assets/documentation/naac/aqar/CR2/2.2.1.pdf",
    "link": "./assets/documentation/naac/aqar/CR2/2.2.1.pdf"
  },
  {
    "title": "2.3.1",
    "filename": "./assets/documentation/naac/aqar/CR2/2.3.1.pdf",
    "link": "./assets/documentation/naac/aqar/CR2/2.3.1.pdf"
  },
  {
    "title": "2.3.2",
    "filename": "./assets/documentation/naac/aqar/CR2/2.3.2.pdf",
    "link": "./assets/documentation/naac/aqar/CR2/2.3.2.pdf"
  },
  {
    "title": "2.3.3",
    "filename": "./assets/documentation/naac/aqar/CR2/2.3.3.pdf",
    "link": "./assets/documentation/naac/aqar/CR2/2.3.3.pdf"
  },
  {
    "title": "2.5.1",
    "filename": "./assets/documentation/naac/aqar/CR2/2.5.1.pdf",
    "link": "./assets/documentation/naac/aqar/CR2/2.5.1.pdf"
  },
  {
    "title": "2.6.2",
    "filename": "./assets/documentation/naac/aqar/CR2/2.6.2.pdf",
    "link": "./assets/documentation/naac/aqar/CR2/2.6.2.pdf"
  },
  {
    "title": "2.6.3",
    "filename": "./assets/documentation/naac/aqar/CR2/2.6.3.pdf",
    "link": "./assets/documentation/naac/aqar/CR2/2.6.3.pdf"
  },
  {
    "title": "2.7 SSS",
    "filename": "./assets/documentation/naac/aqar/CR2/2.7 SSS.pdf",
    "link": "./assets/documentation/naac/aqar/CR2/2.7 SSS.pdf"
  }
]