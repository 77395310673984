export const aqar_cr4=[
  {
    "title": "4.1.1",
    "filename": "./assets/documentation/naac/aqar/CR4/4.1.1.pdf",
    "link": "./assets/documentation/naac/aqar/CR4/4.1.1.pdf"
  },
  {
    "title": "4.1.2",
    "filename": "./assets/documentation/naac/aqar/CR4/4.1.2.pdf",
    "link": "./assets/documentation/naac/aqar/CR4/4.1.2.pdf"
  },
  {
    "title": "4.1.3",
    "filename": "./assets/documentation/naac/aqar/CR4/4.1.3.pdf",
    "link": "./assets/documentation/naac/aqar/CR4/4.1.3.pdf"
  },
  {
    "title": "4.2.1",
    "filename": "./assets/documentation/naac/aqar/CR4/4.2.1.pdf",
    "link": "./assets/documentation/naac/aqar/CR4/4.2.1.pdf"
  },
  {
    "title": "4.2.2",
    "filename": "./assets/documentation/naac/aqar/CR4/4.2.2.pdf",
    "link": "./assets/documentation/naac/aqar/CR4/4.2.2.pdf"
  },
  {
    "title": "4.3.1",
    "filename": "./assets/documentation/naac/aqar/CR4/4.3.1.pdf",
    "link": "./assets/documentation/naac/aqar/CR4/4.3.1.pdf"
  },
  {
    "title": "4.4.2",
    "filename": "./assets/documentation/naac/aqar/CR4/4.4.2.pdf",
    "link": "./assets/documentation/naac/aqar/CR4/4.4.2.pdf"
  }
]