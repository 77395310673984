export const aqar_cr5=[
  {
    "title": "5.1.1",
    "filename": "./assets/documentation/naac/aqar/CR5/5.1.1.pdf",
    "link": "./assets/documentation/naac/aqar/CR5/5.1.1.pdf"
  },
  {
    "title": "5.1.3",
    "filename": "./assets/documentation/naac/aqar/CR5/5.1.3.pdf",
    "link": "./assets/documentation/naac/aqar/CR5/5.1.3.pdf"
  },
  {
    "title": "5.1.5",
    "filename": "./assets/documentation/naac/aqar/CR5/5.1.5.pdf",
    "link": "./assets/documentation/naac/aqar/CR5/5.1.5.pdf"
  },
  {
    "title": "5.3.2",
    "filename": "./assets/documentation/naac/aqar/CR5/5.3.2.pdf",
    "link": "./assets/documentation/naac/aqar/CR5/5.3.2.pdf"
  },
  {
    "title": "5.3.3",
    "filename": "./assets/documentation/naac/aqar/CR5/5.3.3.pdf",
    "link": "./assets/documentation/naac/aqar/CR5/5.3.3.pdf"
  },
  {
    "title": "5.4.1",
    "filename": "./assets/documentation/naac/aqar/CR5/5.4.1.pdf",
    "link": "./assets/documentation/naac/aqar/CR5/5.4.1.pdf"
  }
]