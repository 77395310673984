import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-iqac-mom",
  templateUrl: "./iqac-mom.component.html",
  styleUrls: ["./iqac-mom.component.css"],
})
export class IqacMomComponent implements OnInit {
  momList = [
    {
      title: "Minutes of Meetings 22.11.2018",
      link: "./assets/documentation/iqac/mom/1.MOM 22.11.2018.pdf",
    },
    {
      title: "Minutes of Meetings 09.05.2019",
      link: "./assets/documentation/iqac/mom/2.MOM 09.05.2019.pdf",
    },
    {
      title: "Minutes of Meetings 07.08.2019",
      link: "./assets/documentation/iqac/mom/3.MOM 07.08.2019.pdf",
    },
    {
      title: "Minutes of Meetings 09.07.2020",
      link: "./assets/documentation/iqac/mom/4.MOM 09.07.2020.pdf",
    },
    {
      title: "Minutes of Meetings 17.08.2020",
      link: "./assets/documentation/iqac/mom/5.MOM 17.08.2020.pdf",
    },
    {
      title: "Minutes of Meetings 18.06.2021",
      link: "./assets/documentation/iqac/mom/6.MOM 18.06.2021.pdf",
    },
    {
      title: "Minutes of Meetings 09.09.2021",
      link: "./assets/documentation/iqac/mom/7.MOM 09.09.2021.pdf",
    },
    {
      title: "Minutes of Meetings 21.05.2022",
      link: "./assets/documentation/iqac/mom/8.MOM 21.05.2022.pdf",
    },
    {
      title: "Minutes of Meetings 03.10.2023",
      link: "./assets/documentation/iqac/mom/Minutes of Meeting 03.10.2023.pdf",
    },
    {
      title: "Minutes of Meetings 08.05.2024",
      link: "./assets/documentation/iqac/mom/Minutes of Meeting 08.05.2024.pdf",
    },
    {
      title: "Minutes of Meetings 11.11.2024",
      link: "./assets/documentation/iqac/mom/Minutes of Meeting 11.11.2024.pdf",
    },
  ];
  constructor() {}

  ngOnInit(): void {}
}
