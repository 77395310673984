export const aqar_cr7=[
  {
    "title": "7.1.1.2",
    "filename": "./assets/documentation/naac/aqar/CR7/7.1.1.2.pdf",
    "link": "./assets/documentation/naac/aqar/CR7/7.1.1.2.pdf"
  },
  {
    "title": "7.1.1",
    "filename": "./assets/documentation/naac/aqar/CR7/7.1.1.pdf",
    "link": "./assets/documentation/naac/aqar/CR7/7.1.1.pdf"
  },
  {
    "title": "7.1.3",
    "filename": "./assets/documentation/naac/aqar/CR7/7.1.3.pdf",
    "link": "./assets/documentation/naac/aqar/CR7/7.1.3.pdf"
  },
  {
    "title": "Best Practice",
    "filename": "./assets/documentation/naac/aqar/CR7/Best Practice.docx",
    "link": "./assets/documentation/naac/aqar/CR7/Best Practice.docx"
  },
  {
    "title": "Institutional distinctiveness",
    "filename": "./assets/documentation/naac/aqar/CR7/Institutional distinctiveness.docx",
    "link": "./assets/documentation/naac/aqar/CR7/Institutional distinctiveness.docx"
  }
]