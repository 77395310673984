import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { AppDashboardComponent } from './component/app-dashboard/app-dashboard.component';
import { AboutusComponent } from './component/mainContent/aboutus/aboutus.component';
import { AcademicComponent } from './component/mainContent/academic/academic.component';
import { AdmissionComponent } from './component/mainContent/admission/admission.component';
import { StudentComponent } from './component/mainContent/student/student.component';
import { TnPcellComponent } from './component/mainContent/tn-pcell/tn-pcell.component';
import { CommitteeComponent } from './component/mainContent/committee/committee.component';
import { ActivitiesComponent } from './component/mainContent/activities/activities.component';
import { AlumniComponent } from './component/mainContent/alumni/alumni.component';
import { GalleryComponent } from './component/mainContent/gallery/gallery.component';
import { PrincipalComponent } from './component/mainContent/aboutus/principal/principal.component';
import { FacultyComponent } from './component/mainContent/aboutus/faculty/faculty.component';
import { ContactComponent } from './component/mainContent/aboutus/contact/contact.component';
import { GrievanceComponent } from './component/mainContent/grievance/grievance.component';
import { CareerComponent } from './component/mainContent/career/career.component';
import { NssComponent } from './component/mainContent/activities/nss/nss.component';
import { NonTeachingComponent } from './component/mainContent/aboutus/non-teaching/non-teaching.component';
import { IqacCommitteeComponent } from './component/mainContent/iqac/iqac-committee/iqac-committee.component';
import { IqacInstitutionalDisctinctComponent } from './component/mainContent/iqac/iqac-institutional-disctinct/iqac-institutional-disctinct.component';
import { IqacMomComponent } from './component/mainContent/iqac/iqac-mom/iqac-mom.component';
import { IqacBestPractisesComponent } from './component/mainContent/iqac/iqac-best-practises/iqac-best-practises.component';
import { IqacAqarComponent } from './component/mainContent/NAAC/iqac-aqar/iqac-aqar.component';
import { IqacAqarDocsComponent } from './component/mainContent/iqac/iqac-aqar-docs/iqac-aqar-docs.component';
import { IqacProcPoliciesComponent } from './component/mainContent/iqac/iqac-proc-policies/iqac-proc-policies.component';
import { IqacActionTakenComponent } from './component/mainContent/iqac/iqac-action-taken/iqac-action-taken.component';
import { IiqaComponent } from './component/mainContent/NAAC/iiqa/iiqa.component';
import { SsrComponent } from './component/mainContent/NAAC/ssr/ssr.component';
import { DvvComponent } from './component/mainContent/NAAC/dvv/dvv.component';
import { NaacAddionalLinkComponent } from './component/mainContent/NAAC/naac-addional-link/naac-addional-link.component';
import { LibraryComponent } from './component/mainContent/student/library/library.component';
import { AboutSpmComponent } from './component/mainContent/about-spm/about-spm.component';
import { CurriculamComponent } from './component/mainContent/student/curriculam/curriculam.component';
import { IqcsCompositionComponent } from './component/mainContent/iqac/iqcs-composition/iqcs-composition.component';
import { BestPracticesComponent } from './component/mainContent/NAAC/best-practices/best-practices.component';
import { CoComponent } from './component/mainContent/aboutus/co/co.component';
import { PoComponent } from './component/mainContent/aboutus/po/po.component';
import { InstitutionalDistinctivenessComponent } from './component/mainContent/NAAC/institutional-distinctiveness/institutional-distinctiveness.component';
import { FeedbackComponent } from './component/mainContent/student/feedback/feedback.component';
import { ResearchComponent } from './component/mainContent/activities/research/research.component';
import { SoftSkillComponent } from './component/mainContent/activities/soft-skill/soft-skill.component';
import { MouComponent } from './component/mainContent/activities/mou/mou.component';

const routes: Routes = [
  { path: '', component: AppDashboardComponent },
  { path: 'aboutus', component: AboutusComponent },
  { path: 'academic', component: AcademicComponent },
  { path: 'admission', component: AdmissionComponent },
  { path: 'activities', component: ActivitiesComponent },
  { path: 'student', component: StudentComponent },
  { path: 'library', component: LibraryComponent },
  { path: 'TnPcell', component: TnPcellComponent },
  { path: 'alumni', component: AlumniComponent },
  { path: 'committee', component: CommitteeComponent },
  { path: 'grievance', component: GrievanceComponent },
  { path: 'gallery', component: GalleryComponent },
  { path: 'principal', component: PrincipalComponent },
  { path: 'faculty', component: FacultyComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'career', component: CareerComponent },
  { path: 'nss', component: NssComponent },
  { path: 'non-teaching', component: NonTeachingComponent },
  { path: 'iqac-committee', component: IqacCommitteeComponent },
  { path: 'institutional-distinctiveness', component: IqacInstitutionalDisctinctComponent },
  { path: 'mom', component: IqacMomComponent },
  { path: 'action-taken', component: IqacActionTakenComponent },
  { path: 'best-practises', component: IqacBestPractisesComponent },
  { path: 'aqar', component: IqacAqarComponent },
  { path: 'aqar-docs', component: IqacAqarDocsComponent },
  { path: 'procedure-policies', component: IqacProcPoliciesComponent },
  { path: 'iiqa', component:IiqaComponent },
  { path: 'ssr', component:SsrComponent },
  { path: 'dvv', component:DvvComponent },
  { path: 'naac-addional-links', component:NaacAddionalLinkComponent },
  { path: 'spm', component:AboutSpmComponent },
  { path: 'curriculam', component:CurriculamComponent },
  { path: 'iqac-composition', component:IqcsCompositionComponent },
  { path: 'best-practices', component:BestPracticesComponent },
  { path: 'course-outcomes', component:CoComponent },
  { path: 'program-outcomes', component:PoComponent },
  { path: 'instiddistinct', component:InstitutionalDistinctivenessComponent },
  { path: 'student/feedback', component:FeedbackComponent },
  { path: 'activity/research', component:ResearchComponent },
  { path: 'activity/nss', component:NssComponent },
  { path: 'activity/soft-skill', component:SoftSkillComponent },
  { path: 'activity/mou', component:MouComponent },

];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, { enableTracing: false, useHash: true })
  ],
  declarations: [],
  exports: [RouterModule]
})
export class AppRoutingModule {


}
