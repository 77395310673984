export const aqar_cr3=[
  {
    "title": "3.1.2",
    "filename": "./assets/documentation/naac/aqar/CR3/3.1.2.pdf",
    "link": "./assets/documentation/naac/aqar/CR3/3.1.2.pdf"
  },
  {
    "title": "3.1.3",
    "filename": "./assets/documentation/naac/aqar/CR3/3.1.3.pdf",
    "link": "./assets/documentation/naac/aqar/CR3/3.1.3.pdf"
  },
  {
    "title": "3.3.1",
    "filename": "./assets/documentation/naac/aqar/CR3/3.3.1.pdf",
    "link": "./assets/documentation/naac/aqar/CR3/3.3.1.pdf"
  },
  {
    "title": "3.3.3",
    "filename": "./assets/documentation/naac/aqar/CR3/3.3.3.pdf",
    "link": "./assets/documentation/naac/aqar/CR3/3.3.3.pdf"
  },
  {
    "title": "3.4.1",
    "filename": "./assets/documentation/naac/aqar/CR3/3.4.1.pdf",
    "link": "./assets/documentation/naac/aqar/CR3/3.4.1.pdf"
  }
]