export const importantLinks = [
  {
    displayText: "Prospectus",
    filename: "./assets/documentation/imp/prospectus.pdf",
    Link: "",
  },
  {
    displayText: "Information brochure for academic year 2022-2023",
    filename: "",
    link: "https://ph2022.mahacet.org/ViewPublicDocument?MenuId=1440",
  },
  {
    displayText: "National Academic Depository",
    filename: "",
    link: "http://www.nad.gov.in",
  },
  {
    displayText: "Mandatory Disclosure",
    filename: "./assets/documentation/imp/Mandatory Disclosure.pdf",
    link: "",
  },
  {
    displayText: "Audit Report 2020-21",
    filename: "./assets/documentation/imp/Audit Report 2021-22.pdf",
    link: "",
  },
  {
    displayText: "Final SIF Report 2022-23",
    filename: "./assets/documentation/imp/Final_SIF_Report.pdf",
    link: "",
  },
  {
    displayText: "FRA FEES PROPOSAL 2025-2026",
    filename: "./assets/documentation/imp/FRAFeesProposal2025-26.pdf",
    link: "",
  },
  {
    displayText: "Admission Notification First Year B. Pharmacy 2024-25 ",
    filename: "",
    link: "./assets/documentation/admission/Admission Notification First Year 2024-25.pdf",
  },
  {
    displayText: "Skill Development Result Dec. 2022",
    filename:
      "./assets/documentation/results/CCinAdvancedExcelResultDec2022.pdf",
    link: "https://ph2022.mahacet.org/ViewPublicDocument?MenuId=1440",
  },
  {
    displayText: "Statutory Declaration Under Sec. 4(1)(b) of RTI Act, 2005",
    filename: "./assets/documentation/imp/StatutoryDeclaration.pdf",
    link: "",
  },
];

export const curriculum = [
  {
    displayText: "B. Pharmacy Course Syllabus",
    filename: ".",
    Link: "https://su.digitaluniversity.ac/WebFiles/BOS%20PCI%20Syllabus%20B%20Pharm.pdf",
  },
  {
    displayText: "Skill development Course syllabus",
    filename: ".",
    Link: "https://su.digitaluniversity.ac/WebFiles/Certificate%20Course%20in%20Advance%20Excel.pdf",
  },
  {
    displayText: "Add-on Course Syllabus",
    filename: ".",
    Link: "https://drive.google.com/file/d/1dOPf27zNQ9EgYSqY87IqYI3FKFWOqylQ/view?usp=share_link",
  },
  {
    displayText: "Mentor-Mentee Scheme",
    filename: "./assets/documentation/timetable/2023/Mentor Mentee Notice (1).pdf",
    Link: "./assets/documentation/timetable/2023/Mentor Mentee Notice (1).pdf",
  },
];

export const examNotice = [
  {
    displayName: "Academic result Oct 2022",
    link: "./assets/documentation/exam/2023/up3/Academic result Oct 2022.pdf",
  },
  {
    displayName: "End Semester Exam NUE",
    link: "./assets/documentation/exam/2023/up3/End Semester Exam NUE.pdf",
  },
  {
    displayName: "ESE Practical Exam First and Second year",
    link: "./assets/documentation/exam/2023/up3/ESE Practical Exam First and Second year.pdf",
  },
  {
    displayName: "First sessional exam Timetable Third and Final year",
    link: "./assets/documentation/exam/2023/up3/First sessional exam Timetable Third and Final year.pdf",
  },
  {
    displayName: "Notice regards Democracy",
    link: "./assets/documentation/exam/2023/up3/Notice regards Democracy.pdf",
  },
  {
    displayName: "End Semester Examination NUE",
    link: "./assets/documentation/exam/2023/up2/End Semester Examination NUE .pdf",
  },
  {
    displayName: "End Semester University TT (PR)",
    link: "./assets/documentation/exam/2023/up2/End Semester University TT (PR).pdf",
  },
  {
    displayName: "End Semester University TT(TH)",
    link: "./assets/documentation/exam/2023/up2/End Semester University TT(TH).pdf",
  },
  {
    displayName: "First Sessional SEM II and IV",
    link: "./assets/documentation/exam/2023/up2/First Sessional SEM II and IV.pdf",
  },
  {
    displayName: "Improvement Sessional SEM II and IV",
    link: "./assets/documentation/exam/2023/up2/Improvement Sessional SEM II and IV.pdf",
  },
  {
    displayName: "Improvement Sessional SEM VI",
    link: "./assets/documentation/exam/2023/up2/Improvement Sessional SEM VI.pdf",
  },
  {
    displayName: "Improvement Sessional SEM VIII",
    link: "./assets/documentation/exam/2023/up2/Improvement Sessional SEM VIII.pdf",
  },
  {
    displayName: "Second Sessional SEM II and IV",
    link: "./assets/documentation/exam/2023/up2/Second Sessional SEM II and IV.pdf",
  },
  {
    displayName: "Democracy, Election & Good Governance",
    link: "./assets/documentation/exam/2023/up1/20230722164611.pdf",
  },
  {
    displayName: "Improvement Session Notice 07/07/2023",
    link: "./assets/documentation/exam/2023/up1/20230722164716.pdf",
  },
  {
    displayName: "Project report Submission Notice 19/06/23",
    link: "./assets/documentation/exam/2023/up1/20230722164745.pdf",
  },
  {
    displayName: "Backlog End Semester Exam of EVS",
    link: "./assets/documentation/exam/2023/BacklogEndSemesterExamofEVS.pdf",
  },
  {
    displayName: "First Sessional Exam of TY and Final Year Timetable",
    link: "./assets/documentation/exam/2023/FYTY.pdf",
  },
  {
    displayName: "Improvement sessional Exam first year Timetable.pdf",
    link: "./assets/documentation/exam/2023/ImprovementsessionalExamfirstyearTimetable.pdf",
  },
  {
    displayName: "Improvement Sessional Exam Notice",
    link: "./assets/documentation/exam/2023/Improvement Sessional Exam Notice.pdf",
  },
  {
    displayName: "Improvement Sessional Examination of Second Year",
    link: "./assets/documentation/exam/2023/Improvement Sessional Examination of Second Year.pdf",
  },
  {
    displayName: "Non University End Sem Exam Timetable",
    link: "./assets/documentation/exam/2023/Non University End Sem Exam Timetable.pdf",
  },
  {
    displayName: "Revised First sessional timetable of Third year",
    link: "./assets/documentation/exam/2023/Revised First sessional timetable of Third year.pdf",
  },
  {
    displayName: "Second Sessional Theory exam of First Year TT",
    link: "./assets/documentation/exam/2023/Second Sessional Theory exam of First Year TT",
  },

  {
    displayName: "Evaluation Scheme For Practice School",
    link: "./assets/documentation/exam/Evaluation Scheme For Practice School.pdf",
  },
  {
    displayName: "First Sessional Practical Examination 2022-23",
    link: "./assets/documentation/exam/First Sessional Practical Examination 2022-23.pdf",
  },
  {
    displayName: "Improvement Sessional Examination 2022-23",
    link: "./assets/documentation/exam/Improvement Sessional Examination 2022-23.pdf",
  },
  {
    displayName: "Semester Exam of Practice School",
    link: "./assets/documentation/exam/Notice regarding End Semester Exam of Practice School.pdf",
  },
  {
    displayName: "Improvement sessional exam",
    link: "./assets/documentation/exam/Notice regarding Improvement sessional exam.pdf",
  },
  {
    displayName: "In-semester exam of practice school",
    link: "./assets/documentation/exam/Notice regarding In-semester exam of practice school.pdf",
  },
  {
    displayName: "Practice school presentation",
    link: "./assets/documentation/exam/Notice regarding practice school presentation.pdf",
  },
  {
    displayName: "Practice school report submission",
    link: "./assets/documentation/exam/Notice regarding practice school report submission.pdf",
  },
];

export const calender = [
  {
    displayName: "Academic Calendar 2023-2024",
    link: "./assets/documentation/calender/Aacadmic Calendar 2023-2024.pdf",
  },
  {
    displayName: "Academic Time Table 2023-2024",
    link: "./assets/documentation/calender/Time Table 2023-2024.pdf",
  },
  {
    displayName: "Academic Calendar 2022-2023",
    link: "./assets/documentation/calender/Academic Calendar 2022-2023.pdf",
  },
  {
    displayName: "Academic Time Table",
    link: "./assets/documentation/calender/Academic Time Table.pdf",
  },
];

export const dashboardImages = [
  {
    img: "banner.jpg",
    title: "",
    description: "",
  },
  {
    img: "1.JPG",
    title: "",
    description: "",
  },
  {
    img: "1.JPG",
    title: "",
    description: "",
  },
  {
    img: "1.JPG",
    title: "",
    description: "",
  },
];
