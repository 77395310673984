export const aqar_cr1=[
  {
    "title": "1.1.1 ",
    "filename": "./assets/documentation/naac/aqar/CR1/1.1.1 .pdf",
    "link": "./assets/documentation/naac/aqar/CR1/1.1.1 .pdf"
  },
  {
    "title": "1.1.2",
    "filename": "./assets/documentation/naac/aqar/CR1/1.1.2.pdf",
    "link": "./assets/documentation/naac/aqar/CR1/1.1.2.pdf"
  },
  {
    "title": "1.3.1",
    "filename": "./assets/documentation/naac/aqar/CR1/1.3.1.pdf",
    "link": "./assets/documentation/naac/aqar/CR1/1.3.1.pdf"
  },
  {
    "title": "1.3.2 ",
    "filename": "./assets/documentation/naac/aqar/CR1/1.3.2 .pdf",
    "link": "./assets/documentation/naac/aqar/CR1/1.3.2 .pdf"
  },
  {
    "title": "1.3.3 additional document",
    "filename": "./assets/documentation/naac/aqar/CR1/1.3.3 additional document.pdf",
    "link": "./assets/documentation/naac/aqar/CR1/1.3.3 additional document.pdf"
  },
  {
    "title": "1.3.4 ",
    "filename": "./assets/documentation/naac/aqar/CR1/1.3.4 .pdf",
    "link": "./assets/documentation/naac/aqar/CR1/1.3.4 .pdf"
  },
  {
    "title": "1.4.1 (A) stakeholders in Feedback system",
    "filename": "./assets/documentation/naac/aqar/CR1/1.4.1 (A) stakeholders in Feedback system.pdf",
    "link": "./assets/documentation/naac/aqar/CR1/1.4.1 (A) stakeholders in Feedback system.pdf"
  },
  {
    "title": "1.4.1 (B) action taken reports (Feedback system)",
    "filename": "./assets/documentation/naac/aqar/CR1/1.4.1 (B) action taken reports (Feedback system).pdf",
    "link": "./assets/documentation/naac/aqar/CR1/1.4.1 (B) action taken reports (Feedback system).pdf"
  },
  {
    "title": "1.4.1 (C) Feedback system -",
    "filename": "./assets/documentation/naac/aqar/CR1/1.4.1 (C) Feedback system -.pdf",
    "link": "./assets/documentation/naac/aqar/CR1/1.4.1 (C) Feedback system -.pdf"
  },
  {
    "title": "1.4.2 Feedback system -",
    "filename": "./assets/documentation/naac/aqar/CR1/1.4.2 Feedback system -.pdf",
    "link": "./assets/documentation/naac/aqar/CR1/1.4.2 Feedback system -.pdf"
  }
]