import { Component, OnInit } from '@angular/core';
import { aqar_cr1 } from 'src/app/constant/auto/aqar_cr1.constant';
import { aqar_cr2 } from 'src/app/constant/auto/aqar_cr2.constant';
import { aqar_cr3 } from 'src/app/constant/auto/aqar_cr3.constant';
import { aqar_cr4 } from 'src/app/constant/auto/aqar_cr4.constant';
import { aqar_cr5 } from 'src/app/constant/auto/aqar_cr5.constant';
import { aqar_cr6 } from 'src/app/constant/auto/aqar_cr6.constant';
import { aqar_cr7 } from 'src/app/constant/auto/aqar_cr7.constant';

@Component({
  selector: 'app-iqac-aqar',
  templateUrl: './iqac-aqar.component.html',
  styleUrls: ['./iqac-aqar.component.css']
})
export class IqacAqarComponent implements OnInit {

  readonly aqar_cr1 = aqar_cr1;
  readonly aqar_cr2 = aqar_cr2;
  readonly aqar_cr3 = aqar_cr3;
  readonly aqar_cr4 = aqar_cr4;
  readonly aqar_cr5 = aqar_cr5;
  readonly aqar_cr6 = aqar_cr6;
  readonly aqar_cr7 = aqar_cr7;
  constructor() { }

  ngOnInit(): void {
  }

}
