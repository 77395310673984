import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
//import { ButtonModule } from 'primeng/button';
//import { GalleriaModule } from 'primeng/galleria';
import { AppComponent } from './app.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar'
import { MatSidenavModule } from '@angular/material/sidenav'
import { MatIconModule } from '@angular/material/icon'
import { MatListModule } from '@angular/material/list'
import { MatGridListModule } from '@angular/material/grid-list'
import { MatCardModule } from '@angular/material/card'
import { MatMenuModule } from '@angular/material/menu'
import { MatButtonModule } from '@angular/material/button';
import { AppDashboardComponent } from './component/app-dashboard/app-dashboard.component';
import { MyNavComponent } from './component/my-nav/my-nav.component';
import { AboutusComponent } from './component/mainContent/aboutus/aboutus.component';
import { AcademicComponent } from './component/mainContent/academic/academic.component';
import { ActivitiesComponent } from './component/mainContent/activities/activities.component';
import { StudentComponent } from './component/mainContent/student/student.component';
import { TnPcellComponent } from './component/mainContent/tn-pcell/tn-pcell.component';
import { AlumniComponent } from './component/mainContent/alumni/alumni.component';
import { CommitteeComponent } from './component/mainContent/committee/committee.component';
import { GalleryComponent } from './component/mainContent/gallery/gallery.component';
import { AppRoutingModule } from './/app-routing.module';
import { AdmissionComponent } from './component/mainContent/admission/admission.component';
import { PrincipalComponent } from './component/mainContent/aboutus/principal/principal.component';
import { FacultyComponent } from './component/mainContent/aboutus/faculty/faculty.component';
import { ContactComponent } from './component/mainContent/aboutus/contact/contact.component';
//import { CardModule } from 'primeng/card';
import { GalleryViewerComponent } from './component/GalleryPopup/gallery-viewer/gallery-viewer.component';
import { MatTabsModule } from '@angular/material/tabs';
import { StudentDetailsComponent } from './component/mainContent/student/student-details/student-details.component';
import { HeaderFlagComponent } from './component/header-flag/header-flag.component';
import { GrievanceComponent } from './component/mainContent/grievance/grievance.component';
import { AddComponent } from './component/mainContent/grievance/add/add.component';
import { LoginComponent } from './component/mainContent/grievance/login/login.component';
import { HttpClientModule } from '@angular/common/http';
import { StudentHistoryComponent } from './component/mainContent/grievance/student-history/student-history.component';
import { FormsModule } from '@angular/forms'
import { NgxSpinnerModule } from 'ngx-spinner';

import { NoticePaneComponent } from './component/app-dashboard/notice-pane/notice-pane.component';
import { CareerComponent } from './component/mainContent/career/career.component';
import { NssComponent } from './component/mainContent/activities/nss/nss.component';
import { NonTeachingComponent } from './component/mainContent/aboutus/non-teaching/non-teaching.component';
import { IqacComponent } from './component/mainContent/iqac/iqac.component';
import { IqacCommitteeComponent } from './component/mainContent/iqac/iqac-committee/iqac-committee.component';
import { IqacInstitutionalDisctinctComponent } from './component/mainContent/iqac/iqac-institutional-disctinct/iqac-institutional-disctinct.component';
import { IqacMomComponent } from './component/mainContent/iqac/iqac-mom/iqac-mom.component';
import { IqacActionTakenComponent } from './component/mainContent/iqac/iqac-action-taken/iqac-action-taken.component';
import { IqacBestPractisesComponent } from './component/mainContent/iqac/iqac-best-practises/iqac-best-practises.component';
import { IqacAqarComponent } from './component/mainContent/NAAC/iqac-aqar/iqac-aqar.component';
import { IqacAqarDocsComponent } from './component/mainContent/iqac/iqac-aqar-docs/iqac-aqar-docs.component';
import { IqacProcPoliciesComponent } from './component/mainContent/iqac/iqac-proc-policies/iqac-proc-policies.component';
import { IiqaComponent } from './component/mainContent/NAAC/iiqa/iiqa.component';
import { SsrComponent } from './component/mainContent/NAAC/ssr/ssr.component';
import { DvvComponent } from './component/mainContent/NAAC/dvv/dvv.component';
import { NaacAddionalLinkComponent } from './component/mainContent/NAAC/naac-addional-link/naac-addional-link.component';
import { LibraryComponent } from './component/mainContent/student/library/library.component';
import { AboutSpmComponent } from './component/mainContent/about-spm/about-spm.component';
import { PdfViewerComponent } from './component/utils/pdf-viewer/pdf-viewer.component';
import { CurriculamComponent } from './component/mainContent/student/curriculam/curriculam.component';
import { ExamComponent } from './component/mainContent/student/exam/exam.component';
import { TimetbleComponent } from './component/mainContent/student/timetble/timetble.component';
import { IqcsCompositionComponent } from './component/mainContent/iqac/iqcs-composition/iqcs-composition.component';
import { MaterialModule } from './material/material.module';
import { CriterioTableComponent } from './component/mainContent/NAAC/ssr/criterio-table/criterio-table.component';
import { BestPracticesComponent } from './component/mainContent/NAAC/best-practices/best-practices.component';
import { CoComponent } from './component/mainContent/aboutus/co/co.component';
import { PoComponent } from './component/mainContent/aboutus/po/po.component';
import { InstitutionalDistinctivenessComponent } from './component/mainContent/NAAC/institutional-distinctiveness/institutional-distinctiveness.component';
import { PdfEmbedComponent } from './component/utils/pdf-embed/pdf-embed.component';
import { FeedbackComponent } from './component/mainContent/student/feedback/feedback.component';
import { ResearchComponent } from './component/mainContent/activities/research/research.component';
import { SoftSkillComponent } from './component/mainContent/activities/soft-skill/soft-skill.component';
import { MouComponent } from './component/mainContent/activities/mou/mou.component';


@NgModule({
  declarations: [
    AppComponent,
    MyNavComponent,
    AppDashboardComponent,
    AboutusComponent,
    AcademicComponent,
    ActivitiesComponent,
    StudentComponent,
    TnPcellComponent,
    AlumniComponent,
    CommitteeComponent,
    GalleryComponent,
    AdmissionComponent,
    PrincipalComponent,
    FacultyComponent,
    ContactComponent,
    GalleryViewerComponent,
    StudentDetailsComponent,
    HeaderFlagComponent,
    GrievanceComponent,
    AddComponent,
    LoginComponent,
    StudentHistoryComponent,
    NoticePaneComponent,
    CareerComponent,
    NssComponent,
    NonTeachingComponent,
    IqacComponent,
    IqacCommitteeComponent,
    IqacInstitutionalDisctinctComponent,
    IqacMomComponent,
    IqacActionTakenComponent,
    IqacBestPractisesComponent,
    IqacAqarComponent,
    IqacAqarDocsComponent,
    IqacProcPoliciesComponent,
    IiqaComponent,
    SsrComponent,
    DvvComponent,
    NaacAddionalLinkComponent,
    LibraryComponent,
    AboutSpmComponent,
    PdfViewerComponent,
    CurriculamComponent,
    ExamComponent,
    TimetbleComponent,
    IqcsCompositionComponent,
    CriterioTableComponent,
    BestPracticesComponent,
    CoComponent,
    PoComponent,
    InstitutionalDistinctivenessComponent,
    PdfEmbedComponent,
    FeedbackComponent,
    ResearchComponent,
    SoftSkillComponent,
    MouComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
   // ButtonModule,
   // GalleriaModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatGridListModule,
    MatCardModule,
    MatMenuModule,
    AppRoutingModule,
   // CardModule,
    MatTabsModule,
    NgxSpinnerModule,
    MaterialModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
