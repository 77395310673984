export const aqar_cr6=[
  {
    "title": "6.1.1",
    "filename": "./assets/documentation/naac/aqar/CR6/6.1.1.pdf",
    "link": "./assets/documentation/naac/aqar/CR6/6.1.1.pdf"
  },
  {
    "title": "6.1.2",
    "filename": "./assets/documentation/naac/aqar/CR6/6.1.2.pdf",
    "link": "./assets/documentation/naac/aqar/CR6/6.1.2.pdf"
  },
  {
    "title": "6.2.1",
    "filename": "./assets/documentation/naac/aqar/CR6/6.2.1.pdf",
    "link": "./assets/documentation/naac/aqar/CR6/6.2.1.pdf"
  },
  {
    "title": "6.2.2 (1)",
    "filename": "./assets/documentation/naac/aqar/CR6/6.2.2 (1).pdf",
    "link": "./assets/documentation/naac/aqar/CR6/6.2.2 (1).pdf"
  },
  {
    "title": "6.2.2 (2)",
    "filename": "./assets/documentation/naac/aqar/CR6/6.2.2 (2).pdf",
    "link": "./assets/documentation/naac/aqar/CR6/6.2.2 (2).pdf"
  },
  {
    "title": "6.3.1",
    "filename": "./assets/documentation/naac/aqar/CR6/6.3.1.pdf",
    "link": "./assets/documentation/naac/aqar/CR6/6.3.1.pdf"
  },
  {
    "title": "6.3.5",
    "filename": "./assets/documentation/naac/aqar/CR6/6.3.5.pdf",
    "link": "./assets/documentation/naac/aqar/CR6/6.3.5.pdf"
  },
  {
    "title": "6.4.1",
    "filename": "./assets/documentation/naac/aqar/CR6/6.4.1.pdf",
    "link": "./assets/documentation/naac/aqar/CR6/6.4.1.pdf"
  },
  {
    "title": "6.4.3",
    "filename": "./assets/documentation/naac/aqar/CR6/6.4.3.pdf",
    "link": "./assets/documentation/naac/aqar/CR6/6.4.3.pdf"
  }
]